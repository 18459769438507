import React from "react";
import LocalizedLink from "../localizedLink";

const PagingController = ({prev, next, current}) => {

    return (
        <div className="pagination-bar flex center">
            <LocalizedLink to={prev}  className="prev">
            </LocalizedLink>
            <div className="flex-part flex center">
                <p>ページ：</p>
                <p className="medium page-number">{current}</p>
                <p>
                    / 10
                </p>
            </div>
            <LocalizedLink to={next} className="next">
            </LocalizedLink>
        </div>
    )
}

export default PagingController