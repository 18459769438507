import React from "react";
import Footer from "../../../../components/Footer/Footer";
import SupportLeftNav from "../../../../components/TwoColumnLeftNav/SupportLeftNav";
import PagingController from "../../../../components/PagingController/PagingController";

const Documents = () => {
  // useTranslations is aware of the global context (and therefore also "locale")
  // so it'll automatically give back the right translations

  return (
    <>
      <article className="company-info two-column-left-nav">
        <SupportLeftNav />
        <section className="main-content">
          <div className="temp02_inner">
            <div className="content-container">
              <div className="temp02_history">
                <h1 className={"large bold"}>温度計の世界を見てみよう</h1>
                <h2 className="temp02_font_title medium">
                  温度計の歴史について
                </h2>
                <p className="medium">
                  ある物体が、熱い・冷たいということを、他の誰かに正しく伝えたいという要求は古くからありました。現在用いられている多くの温度計の原理は、物体のもつ熱がそれに接する他の物体に伝わる性質（熱伝導）を利用したもので、一般の家庭で使われている体温計や寒暖計に代表されます。これらは、接触型温度計と呼ばれています。温度計の歴史を見るとこの熱伝導の原理を利用した温度計は、ルネッサンス以降それほど変わっていません。ところが、今世紀に入って目に見える光（可視光線）を利用した、例えば「光高温計」などの温度計が現れました。これは鉄やガラスなどの高温物体を目視しながら、本体に内蔵されたフィラメントの輝度と比較して温度を測ります。その後、目に見えない光（赤外線）を利用した温度計へと発展して行きます。測りたいもの（測定物体）に直接触れなくても、離れた位置から温度が測れるこれからの温度計は、非接触型温度計と呼ばれています。
                </p>
              </div>
              <div className="flex-container">
                <div className="temp02_flex1">
                  <ul>
                    <li>
                      <h3 className="medium temp02_thermometer_title">
                        フィロンの温度検器
                      </h3>
                      <p className="medium">
                        アレキサンドリアの学者フィロンは鉛で出来た容器内の空気が温度変化によって膨張、収縮する原理を利用。熱さを測る装置を作った。
                      </p>
                    </li>
                    <li>
                      <h3 className="medium temp02_thermometer_title">
                        ガリレオの温度計
                      </h3>
                      <p className="medium">
                        ガラス球とガラス管を使って温度を測る装置を作った。ガラスを使っているため温度変化を肉眼で確かめることができた。しかしこの装置は気圧の影響をうけるため短時間の間の温度変化しか見ることができなかった。
                      </p>
                    </li>
                    <li>
                      <h3 className="medium temp02_thermometer_title">
                        サグレドの体温計
                      </h3>
                      <p className="medium">
                        ガリレオの友人で医師のサグレドはガリレオのつくった装置を改良、ガラス管を水平にした中に液滴を入れた。これを用いて友人の医学者サントリオは患者の体温を測ったといわれる。
                      </p>
                    </li>
                    <li>
                      <h3 className="medium temp02_thermometer_title">
                        フィレンツエ（イタリア）でつくられたアルコール温度計
                      </h3>
                      <p className="medium">
                        気体のかわりに、気圧によって体積がほとんど変化しないアルコールを用いた。この温度計は、現在のものとそれほど変わりがないが、統一した目盛りはまだなかった。
                      </p>
                    </li>
                    <li>
                      <h3 className="medium temp02_thermometer_title">
                        ゲーリッケの温度計
                      </h3>
                      <p className="medium">
                        空気ポンプで初めて真空をつくったことで知られるゲーリッケも気体の膨張を利用した温度計を作っている。その著作「真空についてのマグデブルグの新実験」には目盛りを配した温度計が残されている。
                      </p>
                    </li>
                    <li>
                      <h3 className="medium temp02_thermometer_title">
                        ファーレンハイトの水銀温度計
                      </h3>
                      <p className="medium">
                        1720年、実用的な水銀温度計を発明。水銀はアルコールや水とは違って、ガラス管の壁をぬらさず、付着することがないため、同じ熱さの時にはいつも同じ目盛りを指す。そして、この温度計によって「氷の融解点３２度」「水の沸騰点２１２度」を得た。この目盛りは、現在でもアメリカ、カナダなどで使われている。
                      </p>
                    </li>
                  </ul>
                </div>

                <div className="temp02_flex1">
                  <li>
                    <h3 className="medium temp02_thermometer_title">
                      セルシウスの水銀温度計
                    </h3>
                    <p className="medium">
                      スウェーデンの天文学者・物理学者セルシウスは、気圧計の目盛りが一定の時、水の沸騰する温度がつねに一定であることに気づき、一気圧のときの水の沸騰点と、氷の融解点との間を１００等分した目盛りを提唱した。この目盛りは、現在日本など多くの国々で使われている。
                    </p>
                  </li>
                  <li>
                    <h3 className="medium temp02_thermometer_title">
                      熱電気の発見
                    </h3>
                    <p className="medium">
                      氷をいれた容器と、水を入れた容器にビスマス棒を渡し、一方でふたつの銅の容器を太い導線で結び熱すると電気が流れる（熱電気）ことをドイツの物理学者ゼーベックが発見。この原理は後の熱電温度計などに利用されている。
                    </p>
                  </li>
                  <li>
                    <h3 className="medium temp02_thermometer_title">
                      赤外線の発見
                    </h3>
                    <p className="medium">
                      1800年、イギリスの天文学者ハーシェルは太陽光を分光したとき、赤色光の外に物体の温度を高くする領域（赤外線）があることを発見。後の温度計の流れを変える重要な役割を果たした。
                    </p>
                  </li>
                  <li>
                    <h3 className="medium temp02_thermometer_title">
                      プランクの放射法則
                    </h3>
                    <p className="medium">
                      ドイツの物理学者プランクは熱放射のエネルギー量子が赤外線の振動数に比例するとし、6.625*10-27エルグ・秒のプランク定数を導入。それまで連続的と考えられていたエネルギーを、有限な値を持つエネルギー量からできていると規定し、スペクトル分布の放射法則を発見した。
                    </p>
                  </li>
                </div>
              </div>

              <div className="flex-container temp02_div">
                <div className="temp02_flex1">
                  <img
                    src="/asset/Illust01.gif"
                    height="143"
                    width="110"
                    alt="ガリレイ"
                  />

                  <div className="temp02_history_title">
                    <p className="temp02_history_name">
                      G.ガリレイ
                      <br />
                       (1564～1642)
                    </p>
                  </div>
                  <div>
                    <p className="medium">
                      ルネッサンスの代表的なイタリアの物理学者・天文学者、望遠鏡を発明したほか、地動説をとなえたことで有名。
                    </p>
                  </div>
                </div>

                <div className="temp02_flex1">
                  <img
                    src="/asset/Illust02.gif"
                    height="143"
                    width="110"
                    alt="セルシウス"
                  />

                  <div className="temp02_history_title">
                    <p className="temp02_history_name">
                      A.セルシウス
                      <br /> 
                      (1701～1744)
                    </p>
                  </div>
                  <div>
                    <p className="medium">
                      スウェーデンの天文学者・物理学者。セ氏温度の起源になる温度目盛りを提唱した。
                    </p>
                  </div>
                </div>

                <div className="temp02_flex1">
                  <img
                    src="/asset/Illust03.gif"
                    height="143"
                    width="110"
                    alt="ハーシェル"
                  />
                  <div className="temp02_history_title">
                    <p className="temp02_history_name">
                      W.ハーシェル
                      <br />
                       (1738～1822)
                    </p>
                  </div>
                  <div>
                    <p className="medium">
                      イギリスの天文学者、自作の展望鏡で天王星をはじめ太陽の固体運動などを発見したことで有名。1800年、太陽光線の熱と光を観察中、スペクトルの外に目には見えないが熱効果をもつ光ー赤外線を発見した。
                    </p>
                  </div>
                </div>

                <div className="temp02_flex1">
                  <img
                    src="/asset/Illust04.gif"
                    height="143"
                    width="110"
                    alt="プランク"
                  />

                  <div className="temp02_history_title">
                    <p className="temp02_history_name">
                      M.プランク
                      <br />
                       (1858～1947)
                    </p>
                  </div>
                  <div>
                    <p className="medium">
                      ドイツの理論物理学者。熱放射の研究に携わり、スペクトル分布を説明研究する放射法則を発見。後にアインシュタイン、ボーアなどに受け継がれ量子力学の先駆者となった。1918年ノーベル賞受賞。
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <PagingController current={2} next={'/support/documents/about-temperature/temp03'} prev={'/support/documents/about-temperature/temp01'} />
        </section>
      </article>
      <Footer />
    </>
  );
};

export default Documents;
